import {Route, Routes} from "react-router-dom"
import {AuthNavigate} from "../components/AuthNavigate/AuthNavigate"
import {NotFound} from "../layouts/NotFound/NotFound"
import {MGS010} from "../layouts/master/MGS010/MGS010"
import {MLS010} from "../layouts/master/MLS010/MLS010"
import {MLS011} from "../layouts/master/MLS011/MLS011"
import {MLS020} from "../layouts/master/MLS020/MLS020"
import {MLS021} from "../layouts/master/MLS021/MLS021"
import {MLS030} from "../layouts/master/MLS030/MLS030"
import {MLS031} from "../layouts/master/MLS031/MLS031"
import {MLS040} from "../layouts/master/MLS040/MLS040"
import {MLS041} from "../layouts/master/MLS041/MLS041"
import {MLS050} from "../layouts/master/MLS050/MLS050"
import {MLS051} from "../layouts/master/MLS051/MLS051"
import {MLS060} from "../layouts/master/MLS060/MLS060"
import {MLS070} from "../layouts/master/MLS070/MLS070"
import {MLS071} from "../layouts/master/MLS071/MLS071"
import {MLS080} from "../layouts/master/MLS080/MLS080"
import {MLS081} from "../layouts/master/MLS081/MLS081"
import {MLS090} from "../layouts/master/MLS090/MLS090"
import {MLS091} from "../layouts/master/MLS091/MLS091"
import {MLS100} from "../layouts/master/MLS100/MLS100"
import {MLS101} from "../layouts/master/MLS101/MLS101"
import {MLS110} from "../layouts/master/MLS110/MLS110"
import {MLS111} from "../layouts/master/MLS111/MLS111"
import {MLS120} from "../layouts/master/MLS120/MLS120"
import {MLS121} from "../layouts/master/MLS121/MLS121"
import {MLS130} from "../layouts/master/MLS130/MLS130"
import {MLS131} from "../layouts/master/MLS131/MLS131"
import {MLS140} from "../layouts/master/MLS140/MLS140"
import {MLS141} from "../layouts/master/MLS141/MLS141"
import {MLS170} from "../layouts/master/MLS170/MLS170"
import {MLS171} from "../layouts/master/MLS171/MLS171"
import {MLS180} from "../layouts/master/MLS180/MLS180"
import {MLS190} from "../layouts/master/MLS190/MLS190"
import {MLS250} from "../layouts/master/MLS250/MLS250"
import {MLS260} from "../layouts/master/MLS260/MLS260"
import {MLS261} from "../layouts/master/MLS261/MLS261"
import {MOS020} from "../layouts/master/MOS020/MOS020"
import {MOS021} from "../layouts/master/MOS021/MOS021"
import {MOS030} from "../layouts/master/MOS030/MOS030"
import {MOS031} from "../layouts/master/MOS031/MOS031"
import {MSESF021} from "../layouts/master/MSESF021/MSESF021"
import {MSESF022} from "../layouts/master/MSESF022/MSESF022"
import {MSESF023} from "../layouts/master/MSESF023/MSESF023"
import {MSESF030} from "../layouts/master/MSESF030/MSESF030"
import {TDL011} from "../layouts/others/TDL011/TDL011"
import { MSESF024 } from "../layouts/master/MSESF024/MSESF024"

export function MasterRoutes() {
    return <Routes>
        <Route path="/company/create" element={<AuthNavigate access="MARS.MLS011.VIEW"><MLS011 /></AuthNavigate>} />,
        <Route path="/company/edit-:companyCode" element={<AuthNavigate access="MARS.MLS011.VIEW"><MLS011 /></AuthNavigate>} />,
        <Route path="/company/view-:companyCode" element={<AuthNavigate access="MARS.MLS011.VIEW"><MLS011 /></AuthNavigate>} />,
        <Route path="/company" element={<AuthNavigate access="MARS.MLS010.VIEW"><MLS010 /></AuthNavigate>} />,

        <Route path="/bu/create-:buCode" element={<AuthNavigate access="MARS.MLS131.VIEW"><MLS131 /></AuthNavigate>} />,
        <Route path="/bu/edit-:buCode/calendar-edit-:calendarId" element={<AuthNavigate access="MARS.MLS131.VIEW"><MLS131 /></AuthNavigate>} />,
        <Route path="/bu/view-:buCode/calendar-view-:calendarId" element={<AuthNavigate access="MARS.MLS131.VIEW"><MLS131 /></AuthNavigate>} />,
        <Route path="/bu/create" element={<AuthNavigate access="MARS.MLS021.VIEW"><MLS021 /></AuthNavigate>} />,
        <Route path="/bu/edit-:buCode" element={<AuthNavigate access="MARS.MLS021.VIEW"><MLS021 /></AuthNavigate>} />,
        <Route path="/bu/view-:buCode" element={<AuthNavigate access="MARS.MLS021.VIEW"><MLS021 /></AuthNavigate>} />,
        <Route path="/bu" element={<AuthNavigate access="MARS.MLS020.VIEW"><MLS020 /></AuthNavigate>} />,

        <Route path="/customer/create-:customerCode" element={<AuthNavigate access="MARS.MLS131.VIEW"><MLS131 /></AuthNavigate>} />,
        <Route path="/customer/edit-:customerCode/calendar-edit-:calendarId" element={<AuthNavigate access="MARS.MLS131.VIEW"><MLS131 /></AuthNavigate>} />,
        <Route path="/customer/view-:customerCode/calendar-view-:calendarId" element={<AuthNavigate access="MARS.MLS131.VIEW"><MLS131 /></AuthNavigate>} />,
        <Route path="/customer/create" element={<AuthNavigate access="MARS.MLS031.VIEW"><MLS031 /></AuthNavigate>} />,
        <Route path="/customer/edit-:customerCode" element={<AuthNavigate access="MARS.MLS031.VIEW"><MLS031 /></AuthNavigate>} />,
        <Route path="/customer/view-:customerCode" element={<AuthNavigate access="MARS.MLS031.VIEW"><MLS031 /></AuthNavigate>} />,
        <Route path="/customer" element={<AuthNavigate access="MARS.MLS030.VIEW"><MLS030 /></AuthNavigate>} />,

        <Route path="/supplier/create-:supplierCode" element={<AuthNavigate access="MARS.MLS131.VIEW"><MLS131 /></AuthNavigate>} />,
        <Route path="/supplier/edit-:supplierCode/calendar-edit-:calendarId" element={<AuthNavigate access="MARS.MLS131.VIEW"><MLS131 /></AuthNavigate>} />,
        <Route path="/supplier/view-:supplierCode/calendar-view-:calendarId" element={<AuthNavigate access="MARS.MLS131.VIEW"><MLS131 /></AuthNavigate>} />,
        <Route path="/supplier/create" element={<AuthNavigate access="MARS.MLS041.VIEW"><MLS041 /></AuthNavigate>} />,
        <Route path="/supplier/edit-:supplierCode" element={<AuthNavigate access="MARS.MLS041.VIEW"><MLS041 /></AuthNavigate>} />,
        <Route path="/supplier/view-:supplierCode" element={<AuthNavigate access="MARS.MLS041.VIEW"><MLS041 /></AuthNavigate>} />,
        <Route path="/supplier" element={<AuthNavigate access="MARS.MLS040.VIEW"><MLS040 /></AuthNavigate>} />,

        <Route path="/dc/create-:dcCode" element={<AuthNavigate access="MARS.MLS131.VIEW"><MLS131 /></AuthNavigate>} />,
        <Route path="/dc/edit-:dcCode/calendar-edit-:calendarId" element={<AuthNavigate access="MARS.MLS131.VIEW"><MLS131 /></AuthNavigate>} />,
        <Route path="/dc/view-:dcCode/calendar-view-:calendarId" element={<AuthNavigate access="MARS.MLS131.VIEW"><MLS131 /></AuthNavigate>} />,
        <Route path="/dc/create" element={<AuthNavigate access="MARS.MLS051.VIEW"><MLS051 /></AuthNavigate>} />,
        <Route path="/dc/edit-:dcCode" element={<AuthNavigate access="MARS.MLS051.VIEW"><MLS051 /></AuthNavigate>} />,
        <Route path="/dc/view-:dcCode" element={<AuthNavigate access="MARS.MLS051.VIEW"><MLS051 /></AuthNavigate>} />,
        <Route path="/dc" element={<AuthNavigate access="MARS.MLS050.VIEW"><MLS050 /></AuthNavigate>} />,

        <Route path="/port" element={<AuthNavigate access="MARS.MLS060.VIEW"><MLS060 /></AuthNavigate>} />,

        <Route path="/globalparts/create" element={<AuthNavigate access="MARS.MLS071.VIEW"><MLS071 /></AuthNavigate>} />,
        <Route path="/globalparts/edit" element={<AuthNavigate access="MARS.MLS071.VIEW"><MLS071 /></AuthNavigate>} />,
        <Route path="/globalparts" element={<AuthNavigate access="MARS.MLS070.VIEW"><MLS070 /></AuthNavigate>} />,

        <Route path="/unitparts/create" element={<AuthNavigate access="MARS.MLS081.VIEW"><MLS081 /></AuthNavigate>} />,
        <Route path="/unitparts/edit" element={<AuthNavigate access="MARS.MLS081.VIEW"><MLS081 /></AuthNavigate>} />,
        <Route path="/unitparts" element={<AuthNavigate access="MARS.MLS080.VIEW"><MLS080 /></AuthNavigate>} />,

        <Route path="/orderGroup" element={<AuthNavigate access="MARS.MLS140.VIEW"><MLS140 /></AuthNavigate>} />,
        <Route path="/orderGroup/detail-:orderGroupId" element={<AuthNavigate access="MARS.MLS141.VIEW"><MLS141 /></AuthNavigate>} />,

        <Route path="/contract/edit-:contractId" element={<AuthNavigate access="MARS.MLS091.VIEW"><MLS091 /></AuthNavigate>} />,
        <Route path="/contract/view-:contractId" element={<AuthNavigate access="MARS.MLS091.VIEW"><MLS091 /></AuthNavigate>} />,
        <Route path="/contract" element={<AuthNavigate access="MARS.MLS090.VIEW"><MLS090 /></AuthNavigate>} />,

        <Route path="/contractroute/:contractRouteId" element={<AuthNavigate access="MARS.MLS101.VIEW"><MLS101 /></AuthNavigate>} />,
        <Route path="/contractroute" element={<AuthNavigate access="MARS.MLS100.VIEW"><MLS100 /></AuthNavigate>} />,

        <Route path="/announcements/create" element={<AuthNavigate power><MLS261 /></AuthNavigate>} />,
        <Route path="/announcements/edit-:id" element={<AuthNavigate power><MLS261 /></AuthNavigate>} />,
        <Route path="/announcements/view-:id" element={<AuthNavigate power><MLS261 /></AuthNavigate>} />,
        <Route path="/announcements" element={<AuthNavigate power><MLS260 /></AuthNavigate>} />,

        <Route path="/payment/create" element={<AuthNavigate access="MARS.MLS111.VIEW"><MLS111 /></AuthNavigate>} />,
        <Route path="/payment/edit-:paymentTermsId" element={<AuthNavigate access="MARS.MLS111.VIEW"><MLS111 /></AuthNavigate>} />,
        <Route path="/payment/view-:paymentTermsId" element={<AuthNavigate access="MARS.MLS111.VIEW"><MLS111 /></AuthNavigate>} />,
        <Route path="/payment" element={<AuthNavigate access="MARS.MLS110.VIEW"><MLS110 /></AuthNavigate>} />,

        <Route path="/shippingroute/create" element={<AuthNavigate access="MARS.MLS121.VIEW"><MLS121 /></AuthNavigate>} />,
        <Route path="/shippingroute/edit-:srId" element={<AuthNavigate access="MARS.MLS121.VIEW"><MLS121 /></AuthNavigate>} />,
        <Route path="/shippingroute/view-:srId" element={<AuthNavigate access="MARS.MLS121.VIEW"><MLS121 /></AuthNavigate>} />,
        <Route path="/shippingroute" element={<AuthNavigate access="MARS.MLS120.VIEW"><MLS120 /></AuthNavigate>} />,

        <Route path="/calendar/create" element={<AuthNavigate access="MARS.MLS131.VIEW"><MLS131 /></AuthNavigate>} />,
        <Route path="/calendar/edit-:calendarId" element={<AuthNavigate access="MARS.MLS131.VIEW"><MLS131 /></AuthNavigate>} />,
        <Route path="/calendar/view-:calendarId" element={<AuthNavigate access="MARS.MLS131.VIEW"><MLS131 /></AuthNavigate>} />,
        <Route path="/calendar" element={<AuthNavigate access="MARS.MLS130.VIEW"><MLS130 /></AuthNavigate>} />,

        <Route path="/fobSetting/create" element={<AuthNavigate access="MARS.MLS171.VIEW"><MLS171 /></AuthNavigate>} />,
        <Route path="/fobSetting/view-:fobSettingId" element={<AuthNavigate access="MARS.MLS171.VIEW"><MLS171 /></AuthNavigate>} />,
        <Route path="/fobSetting/edit-:fobSettingId" element={<AuthNavigate access="MARS.MLS171.VIEW"><MLS171 /></AuthNavigate>} />,
        <Route path="/fobSetting" element={<AuthNavigate access="MARS.MLS170.VIEW"><MLS170 /></AuthNavigate>} />,

        <Route path="/commondownloadupload" element={<AuthNavigate access="MARS.MGS010.VIEW"><MGS010 /></AuthNavigate>} />,

        <Route path="/schedules/:scheduleId" element={<AuthNavigate access="MARS.TDL011.VIEW"><TDL011 /></AuthNavigate>} />,

        <Route path="/user-setting" element={<AuthNavigate access="MARS.MSESF021.VIEW"><MSESF021 /></AuthNavigate>} />,
        <Route path="/user-setting/authenticatorApp" element={<AuthNavigate><MSESF022 /></AuthNavigate>} />,
        <Route path="/user-setting/recoveryCodes" element={<AuthNavigate><MSESF023 /></AuthNavigate>} />,
        <Route path="/user-setting/mfaRemindSetting" element={<AuthNavigate access="MARS.MSESF024.VIEW"><MSESF024 /></AuthNavigate>} />,
        <Route path="/selfAccessHistory" element={<AuthNavigate access="MARS.MSESF030.VIEW"><MSESF030 /></AuthNavigate>} />,

        <Route path="/requestTo/addNewPart" element={<AuthNavigate access="MARS.MOS021.CREATEADD"><MOS021 /></AuthNavigate>} />,
        <Route path="/requestTo/editPacking" element={<AuthNavigate access="MARS.MOS021.CREATEMODIFY"><MOS021 /></AuthNavigate>} />,
        <Route path="/requestTo/editPrice" element={<AuthNavigate access="MARS.MOS021.CREATEMODIFY"><MOS021 /></AuthNavigate>} />,
        <Route path="/requestTo/remove" element={<AuthNavigate access="MARS.MOS021.CREATEREMOVE"><MOS021 /></AuthNavigate>} />,
        <Route path="/requestTo/edit-:requestNo" element={<AuthNavigate access="MARS.MOS021.EDIT"><MOS021 /></AuthNavigate>} />,
        <Route path="/requestTo/view-:requestNo" element={<AuthNavigate access="MARS.MOS021.VIEW"><MOS021 /></AuthNavigate>} />,
        <Route path="/requestTo" element={<AuthNavigate access="MARS.MOS020.VIEW"><MOS020 /></AuthNavigate>} />,


        <Route path="/receivedRequest/addNewPart-:requestNo/paymentCreate" element={<AuthNavigate access="MARS.MLS111.EDIT"><MLS111 /></AuthNavigate>} />,
        <Route path="/receivedRequest/addNewPart-:requestNo/dcCreate" element={<AuthNavigate access="MARS.MLS051.EDIT"><MLS051 /></AuthNavigate>} />,
        <Route path="/receivedRequest/addNewPart-:requestNo/shippingrouteCreate" element={<AuthNavigate access="MARS.MLS121.EDIT"><MLS121 /></AuthNavigate>} />,
        <Route path="/receivedRequest/addNewPart-:requestNo" element={<AuthNavigate access="MARS.MOS031.EDIT"><MOS031 /></AuthNavigate>} />,
        <Route path="/receivedRequest/modify-:requestNo" element={<AuthNavigate access="MARS.MOS031.EDIT"><MOS031 /></AuthNavigate>} />,
        <Route path="/receivedRequest/modifyPrice-:requestNo" element={<AuthNavigate access="MARS.MOS031.EDIT"><MOS031 /></AuthNavigate>} />,
        <Route path="/receivedRequest/remove-:requestNo" element={<AuthNavigate access="MARS.MOS031.EDIT"><MOS031 /></AuthNavigate>} />,
        <Route path="/receivedRequest/terminate-:requestNo" element={<AuthNavigate access="MARS.MOS031.EDIT"><MOS031 /></AuthNavigate>} />,
        <Route path="/receivedRequest/addNewPartView-:requestNo" element={<AuthNavigate access="MARS.MOS031.VIEW"><MOS031 /></AuthNavigate>} />,
        <Route path="/receivedRequest/modifyView-:requestNo" element={<AuthNavigate access="MARS.MOS031.VIEW"><MOS031 /></AuthNavigate>} />,
        <Route path="/receivedRequest/modifyPriceView-:requestNo" element={<AuthNavigate access="MARS.MOS031.VIEW"><MOS031 /></AuthNavigate>} />,
        <Route path="/receivedRequest/removeView-:requestNo" element={<AuthNavigate access="MARS.MOS031.VIEW"><MOS031 /></AuthNavigate>} />,
        <Route path="/receivedRequest/terminateView-:requestNo" element={<AuthNavigate access="MARS.MOS031.VIEW"><MOS031 /></AuthNavigate>} />,
        <Route path="/receivedRequest" element={<AuthNavigate access="MARS.MOS030.VIEW"><MOS030 /></AuthNavigate>} />,

        <Route path="/creditrules" element={<AuthNavigate access="MASTER.MLS180.VIEW"><MLS180 /></AuthNavigate>} />,
        <Route path="/creditsetting" element={<AuthNavigate access="MASTER.MLS190.VIEW"><MLS190 /></AuthNavigate>} />,
        <Route path="/cargostatus" element={<AuthNavigate access="MARS.MLS250.VIEW"><MLS250 /></AuthNavigate>} />,

        <Route path="*" element={<NotFound scope="master" />} />,
    </Routes>
}
