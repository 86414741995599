import {Project} from "../../../utils/Project"

export type ReminderMfaSettingResponse = Partial<Optional>
export type ReminderMfaSettingResponseJson = Partial<JsonOptional>
export const reminderMfaSettingResponseToJson: Project<ReminderMfaSettingResponse, ReminderMfaSettingResponseJson> = obj => ({
  ...obj,
  reminderTypeCategory: obj.reminderTypeCategory ? obj.reminderTypeCategory.map(m => reminderTypeCategoryListResultToJson(m)) : [],
})
export const reminderMfaSettingResponseFromJson: Project<ReminderMfaSettingResponseJson, ReminderMfaSettingResponse> = json => ({
  ...json,
  reminderTypeCategory: json.reminderTypeCategory ? json.reminderTypeCategory.map(m => reminderTypeCategoryListResultFromJson(m)) : [],
})

interface Optional {
    reminderType: number,
    reminderFrequency: number,
    isEnable: number,
    reminderTypeCategory: ReminderTypeCategoryList[]
}
interface JsonOptional {
    reminderType: number,
    reminderFrequency: number,
    isEnable: number,
    reminderTypeCategory: ReminderTypeCategoryListJson[]                
}

export type ReminderTypeCategoryList = Partial<ReminderTypeCategoryListOptional>
type ReminderTypeCategoryListJson = Partial<ReminderTypeCategoryListJsonOptional>
const reminderTypeCategoryListResultFromJson: Project<ReminderTypeCategoryListJson, ReminderTypeCategoryList> = json => ({
    ...json,
})
const reminderTypeCategoryListResultToJson: Project<ReminderTypeCategoryList, ReminderTypeCategoryListJson> = obj => ({
    ...obj,
})
interface ReminderTypeCategoryListOptional extends ReminderTypeCategoryListJsonOptional {

}
interface ReminderTypeCategoryListJsonOptional {
    codeValue: number,
    codeName:string
}


















