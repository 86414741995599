import {useEffect, useState} from "react"
import {useGetReminderMfaSetting} from "../../../services/system/apis/userMfaSettingApi"
import {MSESF024PcUi} from "./MSESF024PcUi"
import {ReminderTypeCategoryList} from "../../../services/system/models/ReminderMfaSettingResponse"
import {InputMfaReminderSettingFactor} from "../../../services/integration/models/InputMfaReminderSettingFactor"

export const MSESF024 = () => {
  const getReminderMfaSetting = useGetReminderMfaSetting()
  const [category, setCategory] = useState<ReminderTypeCategoryList[]>([])
  const [factor, setFactor] = useState<InputMfaReminderSettingFactor>({
    reminderType: undefined,
    reminderFrequency: undefined,
    isEnable: 0,
  })

  useEffect(() => {
    getReminderMfaSetting(undefined, {silent: true}).then(result => {
      setCategory(result.reminderTypeCategory ?? [])
      if(result.isEnable === 1){
        setFactor({reminderType: result.reminderType, reminderFrequency: result.reminderFrequency, isEnable: result.isEnable})
      }
    })
  }, [getReminderMfaSetting])

  return <MSESF024PcUi
    category={category}
    factor={factor}
    setFactor={setFactor}
  />
}