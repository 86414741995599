import {useCallback} from "react"
import {Projects} from "../../../utils/Projects"
import {useDownload, usePost} from "../../../utils/fetch/useFetch"
import {UserQrInfoResponse, userQrInfoResponseFromJson} from "../../master/models/UserQrInfoResponse"
import {UserMfaResult, userMfaResultFromJson} from "../models/UserMfaResponse"
import { ReminderMfaSettingResponse, reminderMfaSettingResponseFromJson } from "../models/ReminderMfaSettingResponse"
import { InputMfaReminderSettingFactor, inputMfaReminderSettingFactorToJson } from "../../integration/models/InputMfaReminderSettingFactor"

export const useGetMfaStateAndRecover = () => {
    const url = '/common-system-api/user/getMfaStateAndRecover'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(userMfaResultFromJson, [])
    return usePost<undefined, UserMfaResult>(url, payloadProject, resultProject)
}

export const useGetMfaState = () => {
    const url = '/common-system-api/user/getMfaState'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback((state: number)=>state, [])
    return usePost<undefined, number>(url, payloadProject, resultProject)
}

export const useGetMfaRemindState = () => {
    const url = '/common-system-api/user/reminderMfa'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback((state: number)=>state, [])
    return usePost<undefined, number>(url, payloadProject, resultProject)
}

export const useGetReminderMfaSetting = () => {
    const url = '/common-system-api/user/getReminderMfaSetting'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(reminderMfaSettingResponseFromJson, [])
    return usePost<undefined, ReminderMfaSettingResponse>(url, payloadProject, resultProject)
}

export const useReminderMfaSetting = () => {
    const url = '/common-system-api/user/reminderMfaSetting'
    const payloadProject = useCallback(inputMfaReminderSettingFactorToJson, [])
    const resultProject = useCallback(reminderMfaSettingResponseFromJson, [])
    return usePost<InputMfaReminderSettingFactor, ReminderMfaSettingResponse>(url, payloadProject, resultProject)
}

export const useGetQrCode = () => {
    const url = '/common-system-api/user/qrCode'
    const payloadProject = Projects.IGNORE
    const resultProject = useCallback(userQrInfoResponseFromJson, [])
    return usePost<undefined, UserQrInfoResponse>(url, payloadProject, resultProject)
}

export const useSaveMfa = () => {
    const url = '/common-system-api/user/saveMfa'
    const payloadProject = useCallback((obj: {code: string}) => obj.code, [])
    const resultProject = Projects.IGNORE
  return usePost<{code: string}, undefined>(url, payloadProject, resultProject)
}

export const useDownloadRecoverCode = () => {
    const url = '/common-system-api/user/downloadRecoveryCode'
    const payloadProject = Projects.IGNORE
    return useDownload<{}>(url, payloadProject, { fileTimestamp: true })
}

export const useSendResetMfaMail = () => {
    const url = '/common-system-api/user/sendResetMfaMail'
    const payloadProject = Projects.IGNORE
    const resultProject = Projects.IGNORE
    return usePost<undefined, undefined>(url, payloadProject, resultProject)
}


