import {Button, Dialog, DialogContent, DialogContentText, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import {memo, useCallback} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {CallbackViewAction} from "../../../components/Action/CallbackViewAction";
import {SectionCard} from "../../../components/Card/SectionCard";
import {SectionCardContent} from "../../../components/Card/SectionCardContent";
import {SectionCardHeader} from "../../../components/Card/SectionCardHeader";
import {View} from "../../../components/View/View";
import {useDownloadRecoverCode, useSendResetMfaMail} from "../../../services/system/apis/userMfaSettingApi";
import {UserMfaRecoverList} from "../../../services/system/models/UserMfaResponse";

interface MSESF023PcUiProps {
  dialog: boolean,
  resetMfaDialog: boolean,
  setDialog: React.Dispatch<React.SetStateAction<boolean>>,
  setResetMfaDialog: React.Dispatch<React.SetStateAction<boolean>>,
  data: UserMfaRecoverList[]
}

export const MSESF023PcUi = (props: MSESF023PcUiProps) => {
  const {dialog, setDialog, resetMfaDialog, setResetMfaDialog, data} = props
  const intl = useIntl()
  const action = [
    <DownloadAction />,
    <ResetMfaAction setResetMfaDialog={setResetMfaDialog}/>,
    <BackAction url={'/user-setting'} />
  ]

  return <View actions={action}>
    <SectionCard key={1}>
      <SectionCardHeader
        title={intl.formatMessage({id: 'msesf021.recoveryCodes'})}
        subtitle={intl.formatMessage({id: 'msesf021.recoveryDescription'})} />
      <SectionCardContent>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div style={{marginTop: 10, fontWeight: 'bold'}}>
            <FormattedMessage id="msesf023.recoveryTitle" />
          </div>
          <div style={{display: 'flex', alignItems: 'center', marginTop: 10, color: 'red'}}>
            <WarningOutlinedIcon style={{marginRight: 10}} />
              <FormattedMessage id="msesf023.recoverWarning2" />
          </div>
        </div>
        <RecoveryList data={data} />
      </SectionCardContent>
    </SectionCard>
    <MessageDialog dialog={dialog} setDialog={setDialog} />
    <ResetMfaDialog resetMfaDialog={resetMfaDialog} setResetMfaDialog={setResetMfaDialog} />
  </View>
}

const RecoveryList = (props: {data: UserMfaRecoverList[]}) => {
  const {data} = props
  const codeList = data
  return <TableContainer component={Paper} style={{width: 500}}>
    <Table >
      <TableHead>
        <TableRow style={{border: '1px solid'}}>
          <TableCell align="center" style={{width: '60%'}}>
            <FormattedMessage id="msesf023.recoverCode" />
          </TableCell>
          <TableCell align="center" style={{width: '40%'}}>
            <FormattedMessage id="msesf023.isUsed" />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {codeList && codeList.map((item, index) => (
          <TableRow key={index}>
            <TableCell align="center">{item.recoverKey}</TableCell>
            <TableCell align="center">{item.isUsed === 0
              ? <FormattedMessage id="msesf023.no" />
              : <FormattedMessage id="msesf023.yes" />}
            </TableCell>
          </TableRow>
        ))}
        {codeList?.length===0 && 
          <TableRow >
            <TableCell align="center">{'-'}</TableCell>
            <TableCell align="center">{'-'}</TableCell>
          </TableRow>
        }
      </TableBody>
    </Table>
  </TableContainer>
}

const MessageDialog = memo((props: {
  dialog: boolean,
  setDialog: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
  const {dialog, setDialog} = props
  const navigate = useNavigate()
  const onClose = useCallback(() => { 
    setDialog(false)
    navigate(`/user-setting/authenticatorApp`)
  }, [setDialog, navigate])

  return <Dialog open={dialog} onClose={onClose} maxWidth="xl" style={{flex: 1}} BackdropProps={{style: {backgroundColor: 'rgba(0, 0, 0, 0.2)'}}}>
    <DialogTitle style={{textAlign: 'center', marginBottom: -10}}><Typography variant="h3"><FormattedMessage id="msesf023.messageDialogTitle" /></Typography></DialogTitle>
    <DialogContent style={{textAlign: 'center'}}>
      <DialogContentText>
        <FormattedMessage id="msesf023.messageDialogContent" />
      </DialogContentText>
      <Button
        variant={'outlined'}
        color="secondary"
        onClick={onClose}
        style={{
          width: '30%',
        }}>
        <FormattedMessage id="ok" />
      </Button>
    </DialogContent>
  </Dialog>
})

const ResetMfaDialog = memo((props: {
  resetMfaDialog: boolean,
  setResetMfaDialog: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
  const {resetMfaDialog, setResetMfaDialog} = props
  const onClose = useCallback(() => { 
    setResetMfaDialog(false)
  }, [setResetMfaDialog])
  const sendResetMfaMail = useSendResetMfaMail()
  const sendMail = useCallback(() => {
    setResetMfaDialog(false)
    sendResetMfaMail(undefined, {serialized: true}).then(() => {
    }).finally(() => {})
  }, [sendResetMfaMail, setResetMfaDialog])

  return <Dialog open={resetMfaDialog} onClose={onClose} maxWidth="md" style={{flex: 1}} BackdropProps={{style: {backgroundColor: 'rgba(0, 0, 0, 0.2)'}}}>
          <DialogTitle style={{textAlign: 'center', marginBottom: -10}}><Typography variant="h3"><FormattedMessage id="msesf023.resetMfaDialogTitle" /></Typography></DialogTitle>
          <DialogContent style={{textAlign: 'center'}}>
              <DialogContentText style={{textAlign: 'left'}}>
                  <div style={{marginTop: 10, width: 600}}>
                      <FormattedMessage id="msesf023.resetMfaDialogContent1" />
                  </div>
                  <div style={{marginTop: 10, width: 600}}>
                      <FormattedMessage id="msesf023.resetMfaDialogContent2" />
                  </div>
              </DialogContentText>
              <Button
                  variant={'outlined'}
                  color="secondary"
                  onClick={onClose}
                  style={{
                      marginTop: 15,
                      width: '30%',
                      marginRight: 30
                  }}>
                  <FormattedMessage id="cancel" />
              </Button>
              <Button
                  variant={'contained'}
                  color="primary"
                  onClick={sendMail}
                  style={{
                      marginTop: 15,
                      width: '30%',
                  }}>
                  <FormattedMessage id="confirm" />
              </Button>
          </DialogContent>
        </Dialog>
})

const BackAction = (props: {url: string}) => {
  const {url} = props
  const navigate = useNavigate()
  const returnBeforePage = useCallback(() => {
    navigate(url)
  }, [navigate, url])
  return <CallbackViewAction outlined callback={returnBeforePage} title={<FormattedMessage id="back" />} />
}

const DownloadAction = () => {
  const downloadRecoverCode = useDownloadRecoverCode()
  const downloadRecoverCodes = useCallback(() => {
    downloadRecoverCode({}, {serialized: true}).then(() => {
    }).finally(() => {})

  }, [downloadRecoverCode])
  return <CallbackViewAction callback={downloadRecoverCodes} title={<FormattedMessage id="download" />} />
}

const ResetMfaAction = (props: {setResetMfaDialog: React.Dispatch<React.SetStateAction<boolean>>}) => {
  const {setResetMfaDialog} = props
  const open = useCallback(() => { 
    setResetMfaDialog(true)
  }, [setResetMfaDialog])
  return <CallbackViewAction callback={open} title={<FormattedMessage id="resetMFA" />} />
}
