import {useCallback, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {CallbackViewAction} from "../../../components/Action/CallbackViewAction";
import {SectionCard} from "../../../components/Card/SectionCard";
import {SectionCardContent} from "../../../components/Card/SectionCardContent";
import {SectionCardHeader} from "../../../components/Card/SectionCardHeader";
import {View} from "../../../components/View/View";
import {useReminderMfaSetting} from "../../../services/system/apis/userMfaSettingApi";
import { useDispatch } from "react-redux";
import { NumberItem } from "@rithe/form/dist/components/NumberItem";
import { Form } from "@rithe/form/dist/Form";
import { MSESF024CodeItem } from "./MSESF024CodeItem";
import { ReminderMfaSettingResponse, ReminderTypeCategoryList } from "../../../services/system/models/ReminderMfaSettingResponse";
import { Message } from "@rithe/form/dist/types/Message";
import { InputMfaReminderSettingFactor } from "../../../services/integration/models/InputMfaReminderSettingFactor";
import { isNotEmpty, useFieldChecker } from "../../../utils/ValidatorUtils";
import { applicationActions } from "../../Application/applicationSlice";

interface MSESF024PcUiProps {
    category: ReminderTypeCategoryList[],
    factor: InputMfaReminderSettingFactor,
    setFactor: React.Dispatch<React.SetStateAction<InputMfaReminderSettingFactor>>
  }
  
export const MSESF024PcUi = (props: MSESF024PcUiProps) => {
  const {category, factor, setFactor} = props
  const intl = useIntl()

  const remindType = factor.reminderFrequency === 10 ? 'field.targetleadtimeMonth' : factor.reminderFrequency === 20 ? 'field.targetleadtimeWeek' :
    factor.reminderFrequency === 30 ? 'field.targetleadtimeBiweek' : factor.reminderFrequency === 40 ? 'field.targetleadtimeTenDay' : 'field.targetLeadtime'

  const action = [
    <SaveSettingAction remindType={remindType} factor={factor}/>,
    <BackAction url={'/user-setting'} />
  ]

  return <View actions={action}>
    <SectionCard key={1}>
      <SectionCardHeader
        title={intl.formatMessage({id: 'msesf024.title'})}
        subtitle={intl.formatMessage({id: 'msesf024.description'})} />
      <SectionCardContent height={300}>
          <RemingSettings category={category} factor={factor} setFactor={setFactor}/>
      </SectionCardContent>
    </SectionCard>
  </View>
}
  
const RemingSettings = ({category, factor, setFactor}: {category: ReminderTypeCategoryList[], factor: InputMfaReminderSettingFactor, setFactor: React.Dispatch<React.SetStateAction<ReminderMfaSettingResponse>>}) => {
  const intl = useIntl()
  const [messages, setMessages] = useState<Message[]>([])
  const checkFields = useMemo(() => ({
      reminderType: { labelId: 'field.reminderType', required: factor.reminderFrequency ? true : false },
      reminderFrequency: { labelId: 'field.reminderFrequency', length: { max: 2, integer: 2, scale: 0 }, compare: { min: 1 }, required: factor.reminderType ? true : false},
  }), [factor.reminderFrequency, factor.reminderType])
  const filedCheck = useFieldChecker(checkFields, setMessages)

  return <>
    <Form data={factor} setData={setFactor} labelDisplay="block" helperDisplay="none" columnCount={1} messages={messages} setMessages={setMessages} ensure={filedCheck} maxWidth={400}>
        <MSESF024CodeItem field="reminderType" label={intl.formatMessage({ id: 'field.reminderType' })} category={category} />
        <NumberItem field="reminderFrequency" label={intl.formatMessage({ id: "field.reminderFrequency" })}/>
    </Form>
  </>
}

const BackAction = (props: {url: string}) => {
    const {url} = props
    const navigate = useNavigate()
    const returnBeforePage = useCallback(() => {
      navigate(url)
    }, [navigate, url])
    return <CallbackViewAction outlined callback={returnBeforePage} title={<FormattedMessage id="back" />} />
  }

const SaveSettingAction = ({remindType, factor, }: {remindType: string, factor: InputMfaReminderSettingFactor}) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const title = useMemo(() => intl.formatMessage({ id: 'mfaReminderSetting' }), [intl])

  const saveReminderSetting = useReminderMfaSetting()
  const save = useCallback(() => {
    // check: Must fill in both or none
    if ((isNotEmpty(factor.reminderType) && !isNotEmpty(factor.reminderFrequency))) {
        dispatch(applicationActions.pushError({
            title: title,
            messages: { code: 'w0001', args: [intl.formatMessage({ id: 'field.reminderFrequency' })] },
        }))
        return
    }
    if ((!isNotEmpty(factor.reminderType) && isNotEmpty(factor.reminderFrequency))) {
      dispatch(applicationActions.pushError({
          title: title,
          messages: { code: 'w0001', args: [intl.formatMessage({ id: 'field.reminderType' })] },
      }))
      return
    }

    // check reminderFrequency
    let reminderFrequency = factor.reminderFrequency;
    if (isNotEmpty(factor.reminderType) && (reminderFrequency! < 1 || !Number.isInteger(reminderFrequency))) {
        dispatch(applicationActions.pushError({
            title: title,
            messages: { code: 'integerCheck', args: [intl.formatMessage({ id: remindType })] },
        }))
        return
    }
    if(isNotEmpty(factor.reminderType) && isNotEmpty(factor.reminderFrequency)){
      factor.isEnable = 1
    } else {
      factor.isEnable = 0
    }
    saveReminderSetting({ ...factor })
  }, [dispatch, factor, intl, remindType, saveReminderSetting, title])
  
  return <CallbackViewAction callback={save} title={<FormattedMessage id="save" />} />
}


