import {EntryItem, EntryItemProps} from "@rithe/form"
import {Objects} from "@rithe/utils"
import React, {useMemo} from "react"
import {useIntl} from "react-intl"
import { ReminderTypeCategoryList } from "../../../services/system/models/ReminderMfaSettingResponse"

type CodeItemProp = Omit<EntryItemProps, 'entries'> & {
    category: ReminderTypeCategoryList[]
}

export const MSESF024CodeItem = React.memo((props: CodeItemProp) => {
    const {category} = props
    const intl = useIntl()
    const entries = useMemo(() => {
        const mappedData = category.map(cc => ({
            codeValue: cc.codeValue,
            codeName: intl.formatMessage({ id: cc.codeName })
        }));
        return mappedData.map(item => [item.codeValue, item.codeName] as [number, string]);
    }, [category, intl]);
    return <EntryItem {...Objects.delete(props)} entries={entries} />
})
MSESF024CodeItem.displayName = 'CodesItem'