
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useComMasterGetActiveCbds } from "../../services/common/apis/commonMasterApi"
import { TnvCbds } from "../../services/master/models/TnvCbds"
import { useGetMfaRemindState } from "../../services/system/apis/userMfaSettingApi"
import { useApplicationSelector } from "../Application/applicationSlice"
import { homeActions } from "./HomeSlice"
import { HomeUiPc } from "./HomeUiPc"

export const Home = () => {
    const [companyList, setCompanyList] = useState<TnvCbds[]>([])
    const getActiveCbds = useComMasterGetActiveCbds()

    const [mfaWarnDialog, setMfaWarnDialog] = useState<boolean>(false)
    const getMfaWarnState = useGetMfaRemindState()

    useEffect(() => {
        getActiveCbds({ types: [1, 2] }, { silent: true }).then(result => {
            setCompanyList(result || [])
        })
    }, [getActiveCbds, setCompanyList])


    const token = useApplicationSelector(state => state.auth.token)
    const companyUid = useApplicationSelector(state => state.auth.companyUid)
    const dispatch = useDispatch()

    useEffect(() => {
        let timeoutId: any = -1
        if (token && companyUid) {
            timeoutId = setTimeout(() => {
                dispatch(homeActions.listFrequentlyUsedPages())
            }, 100)
        }
        return () => {
            if (token && companyUid) {
                clearTimeout(timeoutId)
            }
        }
    }, [dispatch, companyUid, token])

    useEffect(() => {
        getMfaWarnState(undefined, {silent: true}).then(result => {
            setMfaWarnDialog(result === 1)
        })
    }, [getMfaWarnState, setMfaWarnDialog])

    return <HomeUiPc
        companyList={companyList}
        mfaWarnDialog={mfaWarnDialog}
        setMfaWarnDialog={setMfaWarnDialog}
    />
}
