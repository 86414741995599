import {Project} from "../../../utils/Project"

export type InputMfaReminderSettingFactor = Required & Partial<Optional>
type InputMfaReminderSettingFactorJson = JsonRequired & Partial<JsonOptional>
export const inputMfaReminderSettingFactorToJson: Project<InputMfaReminderSettingFactor, InputMfaReminderSettingFactorJson> = obj => ({
    ...obj,
})

interface Required extends JsonRequired {
}

interface Optional extends JsonOptional {
}

interface JsonRequired {
}

interface JsonOptional {
    reminderType: number,
    reminderFrequency: number,
    isEnable: number,
}